<template>
    <div class="text-left bg-white-alt">
      <NavBarNew :greenLogo='true'/>
      <main>
        <section class="tr-container tr-glasses tr-glasses-become-author--intro">
          <header class="tr__header">
            <h1 class="tr__h1">{{ $t("public.landingPage.title") }}</h1>
            <router-link class="button button--primary" to="/registerAuthor">{{ $t("buttons.registerAsAuthor") }}</router-link>
          </header>
          <article>
            <div class="row">
              <div class="col-12 col-lg-4 tr-order-4 tr-order-1--lg"></div>
              <div class="col-12 col-lg-7 offset-lg-1 tr-order-2 tr-order-2--lg">
                <h3 class="tr__h3">{{ $t("public.landingPage.whoWeAre") }}</h3>
              </div>
              <div class="col-12 col-lg-4 tr-order-4 tr-order-3--lg">
                <div class="tr-photo-card">
                  <img alt="Kdo jsme" src="../../assets/kdo_jsme.jpg">
                </div>
              </div>
              <div class="col-12 col-lg-7 offset-lg-1 tr-order-3 tr-order-4--lg">
                <p>
                  {{ $t("public.landingPage.whoWeAreText") }}
                </p>
              </div>
            </div>
          </article>
        </section>
  
        <section class="tr-container">
          <article>
            <div class="row">
              <div class="col-12 col-lg-4 tr-order-4 tr-order-1--lg"></div>
              <div class="col-12 col-lg-7 offset-lg-1 tr-order-2 tr-order-2--lg">
                <h3 class="tr__h3">{{ $t("public.landingPage.ourApproach") }}</h3>
              </div>
              <div class="col-12 col-lg-4 tr-order-4 tr-order-3--lg">
                <div class="tr-photo-card">
                  <img alt="Náš přístup" src="../../assets/nas_pristup.jpg">
                </div>
              </div>
              <div class="col-12 col-lg-7 offset-lg-1 tr-order-3 tr-order-4--lg">
                <p>
                  {{ $t("public.landingPage.ourApproachText") }}
                </p>
              </div>
            </div>
          </article>
        </section>

        <section class="tr-container">
          <article>
            <div class="row">
              <div class="col-12 col-lg-4 tr-order-4 tr-order-1--lg"></div>
              <div class="col-12 col-lg-7 offset-lg-1 tr-order-2 tr-order-2--lg">
                <h3 class="tr__h3">{{ $t("public.landingPage.whoAreWeLookingFor") }}</h3>
              </div>
              <div class="col-12 col-lg-4 tr-order-4 tr-order-3--lg">
                <div class="tr-photo-card">
                  <img alt="Koho hledáme" src="../../assets/koho_hledame.jpg">
                </div>
              </div>
              <div class="col-12 col-lg-7 offset-lg-1 tr-order-3 tr-order-4--lg">
                <p>
                  {{ $t("public.landingPage.whoAreWeLookingForText1") }}
                </p>
                <p>
                  {{ $t("public.landingPage.whoAreWeLookingForText2") }}
                </p>
                <p>
                  {{ $t("public.landingPage.whoAreWeLookingForText3") }}
                </p>
              </div>
            </div>
          </article>
        </section>
        <section class="tr-container">
          <article>
            <div class="row">
              <div class="col-12 col-lg-4 tr-order-4 tr-order-1--lg"></div>
              <div class="col-12 col-lg-7 offset-lg-1 tr-order-2 tr-order-2--lg">
                <h3 class="tr__h3">{{ $t("public.landingPage.whatIsItAllAbout") }}</h3>
              </div>
              <div class="col-12 col-lg-4 tr-order-4 tr-order-3--lg">
                <div class="tr-photo-card">
                  <img alt="O co jde?" src="../../assets/o_co_jde.jpg">
                </div>
              </div>
              <div class="col-12 col-lg-7 offset-lg-1 tr-order-3 tr-order-4--lg">
                <p>
                  {{ $t("public.landingPage.whatIsItAllAboutText1") }}
                </p>
                <p>
                  {{ $t("public.landingPage.whatIsItAllAboutText2") }}
                </p>
              </div>
            </div>
          </article>
        </section>
        <section class="tr-container">
          <article>
            <div class="row">
              <div class="col-12 col-lg-4 tr-order-4 tr-order-1--lg"></div>
              <div class="col-12 col-lg-7 offset-lg-1 tr-order-2 tr-order-2--lg">
                <h3 class="tr__h3">{{ $t("public.landingPage.whatWillYouGetOutOfIt") }}</h3>
              </div>
              <div class="col-12 col-lg-4 tr-order-4 tr-order-3--lg">
                <div class="tr-photo-card">
                  <img alt="Co za to?" src="../../assets/co_za_to.jpg">
                </div>
              </div>
              <div class="col-12 col-lg-7 offset-lg-1 tr-order-3 tr-order-4--lg">
                <p>
                  {{ $t("public.landingPage.whatWillYouGetOutOfItText1") }}
                </p>
                <p>
                  <i18n path="public.landingPage.whatWillYouGetOutOfItText2" :tag="false">
                    <template v-slot:trekioInfoEmail>
                        <a href="mailto:info@trekio.com">info@trekio.com</a>
                    </template>
                </i18n>
                </p>
              </div>
            </div>
          </article>
        </section>
        <section class="tr-container">
            <div class="row">
                <div class="col-12 col-lg-7 offset-lg-5">
                    <h3 class="tr__h3">{{ $t("public.landingPage.wantToBecomeAuthor") }}</h3>
                </div>
                <div class="col-12 col-lg-7 offset-lg-5">
                    <router-link to="/registerAuthor"><button class="button button--primary">{{ $t("buttons.registerAsAuthor") }}</button></router-link>
                </div>
            </div>
        </section>
      </main>
      <Footer/>
    </div>
  </template>
  
  <script>
  import Footer from "@/components/Footer";
  
  export default {
  
    name: "LandingPage",
    components: {
      Footer
    }
  }
  </script>
  
  <style scoped lang="scss">

@import "../../global";


    .tr-photo-card {
        padding-bottom: 46px;
        margin-bottom: 0;
    }

    section {
        padding-bottom: 80px;
    }
  
  </style>